import logo from "./logo.svg";
import "./App.css";
import { findAllByAltText } from "@testing-library/react";
import { useMediaDevices } from "react-media-devices";
import { useEffect } from "react";

function App() {
  const { devices, loading } = useMediaDevices({
    constraints: { audio: true, video: true },
    onError: console.log,
  });

  useEffect(() => {
    if (devices) {
      window.confirm(JSON.stringify(devices));
      console.log(devices);
    }
  }, [devices]);

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
